import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import './productgriditem.css';
import './PromoForm.css';
import Form from './form';

/* Twoje importy obrazków/ikon/itp. */
import xsik from './strzalka.svg';
import aliexpressLogo from './aliexpress.png';
import aliexpressLogoMini from './aliexpressmini.png';
import amazonLogo from './amazon.png';
import amazonLogoMini from './amazonMini.png';
import temuLogo from './logotemu.png';
import temuLogoMini from './temu.png';
import copyImg from '../src/Copy-24.png';
import discountImg from '../src/discount.png';
import ig from './ig.png';
import yt from './yt.png';
import tt from './tt.png';
import fb from './fb.png';
import searchIcon from './searchIcon.png';
import CategoryList from './categories';
import piekneOverlay from './piekne.png';

/* 
  Jeśli masz obrazek do overlaya, zaimportuj go tak:
  import inappOverlayImg from './inapp_overlay.png';
*/

const companyLogos = {
  aliexpress: aliexpressLogo,
  amazon: amazonLogo,
  temu: temuLogo,
};

const getCompanyFromUrl = (url) => {
  if (!url) return null;
  if (url.includes('aliexpress')) return 'aliexpress';
  if (url.includes('amazon') || url.includes('amzn')) return 'amazon';
  if (url.includes('temu')) return 'temu';
  return null;
};

/* ----- Komponent PostWithBackground ----- */
const PostWithBackground = ({ thumbnailSrc, videoSrc, onClick }) => {
  const handleThumbnailClick = () => {
    onClick(videoSrc, thumbnailSrc);
  };

  return (
    <img
      className="post-with-background product"
      src={thumbnailSrc}
      alt="Product Thumbnail"
      loading="lazy"
      onClick={handleThumbnailClick}
    />
  );
};

/* ----- FullScreenDiv: wideo + orange-div ----- */
const FullScreenDiv = ({ onClose, links, showOrangeDiv, setShowOrangeDiv, videoSrc, thumbnailSrc }) => {
  const videoRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  useEffect(() => {
    window.history.pushState({ fullScreenDiv: true }, '');
    const handlePopState = (event) => {
      if (event.state && event.state.fullScreenDiv) {
        onClose();
      }
    };
    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, [onClose]);

  const toggleVideoPlayPause = () => {
    if (!videoRef.current) return;
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsVideoPlaying(true);
    } else {
      videoRef.current.pause();
      setIsVideoPlaying(false);
    }
  };

  const handleBottomButtonClick = () => {
    setShowOrangeDiv(!showOrangeDiv);
    if (!showOrangeDiv) {
      window.history.pushState({ showOrangeDiv: true }, '');
    }
  };

  return (
    <div className="full-screen-div-overlay" onClick={onClose}>
      <div
        className="full-screen-div-content"
        style={{ backgroundImage: `url(${thumbnailSrc})` }}
        onClick={(e) => e.stopPropagation()}
      >
        <video
          ref={videoRef}
          src={videoSrc}
          loop
          onClick={toggleVideoPlayPause}
          onEnded={() => setIsVideoPlaying(false)}
          playsInline
        />

        <button className="bottom-button" onClick={handleBottomButtonClick}>
          <div className="bottom-button-content">
            <div className="bottom-button-expand-text">Pokaż Produkty</div>
            <img className="linkImg" src="link.png" alt="Opis obrazka" />
          </div>
        </button>

        {isVideoPlaying ? (
          <div className="play-pause-icon" onClick={toggleVideoPlayPause} />
        ) : (
          <div className="play-pause-icon" onClick={toggleVideoPlayPause}>
            &#9654;
          </div>
        )}

        {showOrangeDiv && (
          <div className="orange-div">
            <div className="linkiHeader">
              <div className="LinkiDoProduktow" />
              <div className="x" onClick={() => setShowOrangeDiv(false)}>
                <img className="xus" src={xsik} alt="Opis obrazka" />
              </div>
            </div>
            <div className="product-grid-item">
              {links.map((link, index) => (
                <ProductItem key={index} link={link} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

/* ----- ProductItem w orange-div ----- */
const ProductItem = ({ link }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => setIsExpanded(!isExpanded);
  if (!link || !link.url) return null;

  return (
    <div className="product-item" onClick={toggleExpand}>
      <img src={link.mainImageSrc || link.imageSrc} alt={link.text} className="product-thumbnail" />
      <div className="product-info">
        <span>{link.text}</span>
      </div>
      {isExpanded && (
        <div className="expanded-links">
          {link.additionalLinks && link.additionalLinks.length > 0 ? (
            <AdditionalLinksItem link={link} />
          ) : (
            <SingleLinkItem link={link} />
          )}
        </div>
      )}
    </div>
  );
};

const SingleLinkItem = ({ link }) => {
  const companyLogo = getCompanyFromUrl(link.url) ? companyLogos[getCompanyFromUrl(link.url)] : null;
  const getBackgroundColor = (url) => {
    const company = getCompanyFromUrl(url);
    switch (company) {
      case 'aliexpress': return '#E52D01';
      case 'amazon':     return '#232f3e';
      case 'temu':       return '#FC7701';
      default:           return '#FFFFFF';
    }
  };
  return (
    <div
      className="additional-links-container"
      style={{ backgroundColor: getBackgroundColor(link.url), borderRadius: '8px' }}
    >
      <a href={link.url} target="_blank" rel="noopener noreferrer" className="additional-link-item">
        {companyLogo && <img src={companyLogo} alt="Company Logo" className="company-logo" />}
      </a>
    </div>
  );
};

const AdditionalLinksItem = ({ link }) => {
  const getBackgroundColor = (url) => {
    const company = getCompanyFromUrl(url);
    switch (company) {
      case 'aliexpress': return '#E52D01';
      case 'amazon':     return '#232f3e';
      case 'temu':       return '#FC7701';
      default:           return '#FFFFFF';
    }
  };
  return (
    <div className="additional-links-container">
      {link.additionalLinks.map((additionalLink, index) => (
        <a
          href={additionalLink.url}
          target="_blank"
          rel="noopener noreferrer"
          className="additional-link-item"
          key={index}
          style={{ backgroundColor: getBackgroundColor(additionalLink.url) }}
        >
          {getCompanyFromUrl(additionalLink.url) && (
            <img
              src={companyLogos[getCompanyFromUrl(additionalLink.url)]}
              alt={getCompanyFromUrl(additionalLink.url)}
              className="company-logo"
            />
          )}
        </a>
      ))}
    </div>
  );
};

/* ----- Promo Codes ----- */
const PromoCodesList = ({ promoCodesMini }) => {
  const getBackgroundColor = (company) => {
    switch (company) {
      case 'Amazon':     return '#232f3e';
      case 'AliExpress': return '#E52D01';
      case 'TEMU':       return '#FC7701';
      default:           return '#FFFFFF';
    }
  };
  const getButtonColor = (company) => {
    switch (company) {
      case 'Amazon':     return '#146eb4';
      case 'AliExpress': return '#FF552D';
      case 'TEMU':       return '#FF902E';
      default:           return '#FFFFFF';
    }
  };

  return (
    <div className="promo-codes-list">
      {promoCodesMini.map((promoCode, index) => (
        <PromoCode
          key={index}
          logoSrc={promoCode.logoSrc}
          promoCode={promoCode.promoCode}
          description={promoCode.description}
          backgroundColor={getBackgroundColor(promoCode.company)}
          buttonColor={getButtonColor(promoCode.company)}
          linkUrl={promoCode.linkUrl}
        />
      ))}
    </div>
  );
};

const PromoCode = ({ logoSrc, promoCode, description, backgroundColor, buttonColor, linkUrl }) => {
  const handleCopyClick = () => {
    navigator.clipboard.writeText(promoCode);
    alert(`Kod ${promoCode} został skopiowany do schowka!`);
  };

  return (
    <div className="promo-code-container" style={{ backgroundColor }}>
      <div className="promo-logo-container">
        <img src={logoSrc} alt="Company Logo" className="promo-company-logo" />
      </div>
      <div className="promo-details">
        <span className="promo-description">{description}</span>
        <div className="promo-code-section">
          <span className="promo-code">{promoCode}</span>
          <button className="copy-button" onClick={handleCopyClick}>
            <img className="copyImg" src={copyImg} alt="Opis obrazka" />
          </button>
        </div>
        <a href={linkUrl}>
          <button className="shop-button" style={{ backgroundColor: buttonColor }}>
            Przejdź do sklepu!
          </button>
        </a>
      </div>
    </div>
  );
};

/* ----- Główny komponent App ----- */
function App() {
  // ============ OVERLAY dla TikTok/Facebook in-app browser ============
  const [showInAppOverlay, setShowInAppOverlay] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
  
    const isTikTok = (
      userAgent.includes('tiktok') ||
      userAgent.includes('musical_ly') ||
      userAgent.includes('musically') ||
      userAgent.includes('ttwebview') ||
      userAgent.includes('com.zhiliaoapp') ||
      userAgent.includes('douyin')
    );
  
    const isFacebook = userAgent.includes('fb');
    const isInstagram = userAgent.includes('instagram');
  
    if (isTikTok || isFacebook || isInstagram) {
      setShowInAppOverlay(true);
    }
  }, []);
  
  

  const handleCloseInAppOverlay = () => {
    setShowInAppOverlay(false);
  };

  // ===================================================================

  const [clickedVideoSrc, setClickedVideoSrc] = useState(null);
  const [thumbnailSrc, setThumbnailSrc] = useState('');
  const [searchCode, setSearchCode] = useState('');
  const [fullScreenDiv, setFullScreenDiv] = useState(false);
  const [currentLinks, setCurrentLinks] = useState([]);
  const [showOrangeDiv, setShowOrangeDiv] = useState(false);

  const [productData, setProductData] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  /* Liczba wczytanych postów (lazy loading) */
  const [loadedProducts, setLoadedProducts] = useState(9);  // startujemy od 9
  const [isLoading, setIsLoading] = useState(false);        // spinner
  const loadMoreRef = useRef(null);

  const [showForm, setShowForm] = useState(false);
  const [showPromoCodes, setShowPromoCodes] = useState(false);

  /* Kody promocyjne (przykładowe) */
  const promoCodesMini = [
    { logoSrc: aliexpressLogoMini, promoCode: 'IFP9MDH', description: '⭐20zł zniżki przy zakupach za 200 zł⭐', company: 'AliExpress', linkUrl: 'https://s.click.aliexpress.com/e/_Ey5J3Ob' },
    { logoSrc: temuLogoMini, promoCode: 'apk84760', description: '⭐Nowi użytkownicy⭐ 400zł kuponów!', company: 'TEMU', linkUrl: 'https://temu.to/m/ejfih3dvqq9' },
  ];

  /* Kategorie + scroll dotykowy */
  const categoriesRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [activeCategory, setActiveCategory] = useState(null);

  // 1) Pobieranie produktów z API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://polskie-amalinki.pl/api/products');
        if (!response.ok) throw new Error('Network response was not ok');

        const json = await response.json();
        setProductData(json);
        setFilteredProducts(json);
      } catch (error) {
        console.error('Fetch error:', error);
      }
    };
    fetchData();
  }, []);

  // IntersectionObserver
useEffect(() => {
  const handleIntersection = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      if (loadedProducts < filteredProducts.length) {
        setIsLoading(true);
        // Symulacja 1 sek. ładowania
        setTimeout(() => {
          setLoadedProducts((prev) => prev + 6);
          setIsLoading(false);
        }, 1000);
      }
    }
  };

  const observer = new IntersectionObserver(handleIntersection, {
    root: null,
    rootMargin: '0px 0px 200px 0px', // ważne
    threshold: 0.0,                 // ważne
  });

  if (loadMoreRef.current) {
    observer.observe(loadMoreRef.current);
  }

  return () => {
    if (loadMoreRef.current) {
      observer.unobserve(loadMoreRef.current);
    }
  };
}, [filteredProducts, loadedProducts]);


  // 3) Ładowanie filmu wg ?product
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const productId = urlParams.get('product');
    if (productId) {
      const product = productData.find((item) => item.code === productId);
      if (product) {
        handleThumbnailClick(product.videoSrc, product.thumbnailSrc, product.links, productId);
      }
    }
  }, [productData]);

  // 4) Obsługa historii browser
  useEffect(() => {
    const handlePopState = (event) => {
      if (event.state && event.state.fullScreenDiv) {
        const product = productData.find((item) => item.code === event.state.productId);
        if (product) {
          handleThumbnailClick(product.videoSrc, product.thumbnailSrc, product.links, event.state.productId);
        }
      } else {
        closeFullScreenDiv();
      }
    };
    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, [productData]);

  useEffect(() => {
    const handlePopState = () => {
      if (showOrangeDiv) {
        setShowOrangeDiv(false);
        if (fullScreenDiv) {
          window.history.replaceState({ fullScreenDiv: true }, '');
        }
      } else if (fullScreenDiv) {
        closeFullScreenDiv();
      } else if (searchCode) {
        setSearchCode('');
        setFilteredProducts(productData);
      } else {
        window.history.pushState(null, '', window.location.href);
      }
    };
    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, [searchCode, fullScreenDiv, showOrangeDiv, productData]);

  const handleThumbnailClick = (videoSrc, thumbnailSrc, links, productId) => {
    setFullScreenDiv(true);
    setCurrentLinks(links);
    if (window.innerWidth > 1000) setShowOrangeDiv(true);
    else setShowOrangeDiv(false);

    setClickedVideoSrc(videoSrc);
    setThumbnailSrc(thumbnailSrc);
    window.history.pushState({ fullScreenDiv: true, productId }, '', `?product=${productId}`);
  };

  const closeFullScreenDiv = () => {
    setFullScreenDiv(false);
    setCurrentLinks([]);
    setShowOrangeDiv(false);
    window.history.pushState(null, '', window.location.pathname);
  };

  const handleSearch = (searchValue) => {
    if (searchValue === 'DODAJ9') {
      setShowForm(true);
      return;
    }
    if (!searchValue) {
      setFilteredProducts(productData);
      setLoadedProducts(9);
    } else {
      const foundProducts = [];
      productData.forEach((product) => {
        if (product.code.includes(searchValue)) {
          product.links.forEach((link) => {
            if (link.additionalLinks?.length > 0) {
              foundProducts.push({ ...link, mainImageSrc: link.imageSrc });
            } else {
              foundProducts.push(link);
            }
          });
        } else {
          product.links.forEach((link) => {
            if (link.text.toLowerCase().includes(searchValue.toLowerCase())) {
              if (link.additionalLinks?.length > 0) {
                foundProducts.push({ ...link, mainImageSrc: link.imageSrc });
              } else {
                foundProducts.push(link);
              }
            }
          });
        }
      });
      setFilteredProducts(foundProducts);
      setLoadedProducts(9);
    }
  };

  const handlePromoButtonClick = () => {
    setShowPromoCodes(!showPromoCodes);
  };

  const handleCategoryClick = (category) => {
    if (activeCategory === category) {
      setFilteredProducts(productData);
      setActiveCategory(null);
    } else {
      const filtered = productData.filter((p) => p.category === category);
      setFilteredProducts(filtered);
      setActiveCategory(category);
    }
    setLoadedProducts(9);
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].pageX - categoriesRef.current.offsetLeft);
    setScrollLeft(categoriesRef.current.scrollLeft);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const x = e.touches[0].pageX - categoriesRef.current.offsetLeft;
    const walk = (x - startX) * 1.2;
    categoriesRef.current.scrollLeft = scrollLeft - walk;
    e.preventDefault();
  };
  const handleTouchEnd = () => setIsDragging(false);

  return (
    <div className="App">
      {/* 
        ============== OVERLAY NA IN-APP BROWSER (TIKTOK/FACEBOOK) ============== 
      */}
      {showInAppOverlay && (
        /* Kliknięcie w tło zamyka overlay */
        <div className="inapp-overlay" onClick={handleCloseInAppOverlay}>
          <div className="inapp-content" onClick={(e) => e.stopPropagation()}>
            {/* 
              Tu wstawiasz swoją grafikę lub <img> 
            */}
            <img
              src={piekneOverlay}
              alt="Instrukcja"
              className="inapp-overlay-img"
            />
            {/* Guzik "X" w rogu 
            <button className="close-inapp" onClick={handleCloseInAppOverlay}>
              X
            </button>
            */}
            {/* Dodatkowy div Zamknij – klika się i zamyka overlay */}
            <div className="close-text" onClick={handleCloseInAppOverlay}>
              
            </div>
          </div>
        </div>
      )}
      {/* ============== KONIEC OVERLAY ============== */}

      <header className="App-header">
        <div className="logo" />
        <div className="socialsIcons">
          <a href="https://www.tiktok.com/@polskaamazonka" className="tt">
            <img className="socialIcon" src={tt} alt="Opis obrazka" />
          </a>
          <a href="https://www.facebook.com/PolskaAmazonka/" className="fb">
            <img className="socialIcon" src={fb} alt="Opis obrazka" />
          </a>
          <a href="https://www.instagram.com/polska.amazonka/" className="ig">
            <img className="socialIcon" src={ig} alt="Opis obrazka" />
          </a>
          <a href="https://www.youtube.com/@PolskaAmazonka" className="yt">
            <img className="socialIcon" src={yt} alt="Opis obrazka" />
          </a>
        </div>
      </header>

      <main className="main-content">
        <div className="search-bar">
          <input
            className="inpucik"
            type="text"
            placeholder="Znajdź produkt po nazwie"
            value={searchCode}
            onChange={(e) => {
              setSearchCode(e.target.value);
              handleSearch(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch(searchCode);
                e.target.blur();
              }
            }}
          />
          <button
            className="search-icon"
            onClick={() => {
              handleSearch(searchCode);
              document.activeElement.blur();
            }}
          >
            <img src={searchIcon} alt="Search" />
          </button>
        </div>

        <p className="WelcomeAmazonka">
          Witaj w wyszukiwarce najtańszych linków
          <br />
          🤠Polskiej Amazonki🏹
        </p>
        <div className="PromoButton" onClick={handlePromoButtonClick}>
          <img className="discountImg" src={discountImg} alt="Opis obrazka" />
          Odkryj Zniżki
        </div>
        {showPromoCodes && <PromoCodesList promoCodesMini={promoCodesMini} />}
        <hr className="hrstyle" />

        <div
          className="categories"
          ref={categoriesRef}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <button
            className={`category-button amazonkaTopkaButton ${activeCategory === 'best' ? 'active-category' : ''}`}
            onClick={() => handleCategoryClick('best')}
          />
          <button
            className={`category-button temuButton ${activeCategory === 'temu' ? 'active-category' : ''}`}
            onClick={() => handleCategoryClick('temu')}
          />
          <button
            className={`category-button aliButton ${activeCategory === 'aliexpress' ? 'active-category' : ''}`}
            onClick={() => handleCategoryClick('aliexpress')}
          />
          <button
            className={`category-button homecategory ${activeCategory === 'home' ? 'active-category' : ''}`}
            onClick={() => handleCategoryClick('home')}
          />
          <button
            className={`category-button findscategory ${activeCategory === 'finds' ? 'active-category' : ''}`}
            onClick={() => handleCategoryClick('finds')}
          />
          <button
            className={`category-button kidscategory ${activeCategory === 'kids' ? 'active-category' : ''}`}
            onClick={() => handleCategoryClick('kids')}
          />
          <button
            className={`category-button petscategory ${activeCategory === 'pets' ? 'active-category' : ''}`}
            onClick={() => handleCategoryClick('pets')}
          />
        </div>

        {!fullScreenDiv && searchCode === '' && (
          <div className="product-grid">
            {filteredProducts.slice(0, loadedProducts).map((data, index) => (
              <PostWithBackground
                key={index}
                thumbnailSrc={data.thumbnailSrc}
                videoSrc={data.videoSrc}
                onClick={() => handleThumbnailClick(data.videoSrc, data.thumbnailSrc, data.links, data.code)}
              />
            ))}
            <div ref={loadMoreRef} style={{ height: '1px' }} />
            {isLoading && (
              <div className="spinner-container">
                <div className="spinner" />
              </div>
            )}
          </div>
        )}

        {!fullScreenDiv && searchCode !== '' && (
          <div className="product-grid-item">
            {filteredProducts.slice(0, loadedProducts).map((link, index) => (
              <ProductItem key={index} link={link} />
            ))}
            <div ref={loadMoreRef} style={{ height: '1px' }} />
            {isLoading && (
              <div className="spinner-container">
                <div className="spinner" />
              </div>
            )}
          </div>
        )}

        {fullScreenDiv && (
          <FullScreenDiv
            onClose={closeFullScreenDiv}
            links={currentLinks}
            showOrangeDiv={showOrangeDiv}
            setShowOrangeDiv={setShowOrangeDiv}
            videoSrc={clickedVideoSrc}
            thumbnailSrc={thumbnailSrc}
          />
        )}

        {showForm && <Form onClose={() => setShowForm(false)} />}
      </main>

      <footer className="App-footer"></footer>
    </div>
  );
}

export default App;
